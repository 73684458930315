import {
    DateInput,
} from 'semantic-ui-calendar-react';
import React, {useState} from "react";
import moment from 'moment';
import 'moment/locale/he';
import {HebcalService} from "../services/hebcal-service";
import {Container, Header, Popup} from "semantic-ui-react";
import "./Calendar.css";
import logo from "../assets/logo.png";

function Calendar() {
    const hebcalService = new HebcalService();
    const holidays = hebcalService.getAllHolidaysDates().map(x => x.getDate().greg());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleChange = (event, {name, value}) => {
        const splitVal = value.split("-");
        const valueDate = new Date(splitVal[2], Number(splitVal[1]) - 1, splitVal[0]);
        setSelectedDate(valueDate);
    }
    const formattedDate = () => {
        const date = new Date().toLocaleDateString("he");
        return date.replace(".", "-");
    }
    const getDescriptiveHeb = () => {
        const hebDate = (hebcalService.getHebrewDate(selectedDate));
        const events = hebcalService.getEvent(selectedDate);
        return <div dir="rtl">
            <b>{hebDate}</b><br/>
            {events?.map(x => <li>{x.render("he")}<br/></li>)}
        </div>;
    }

    return (
        <Container text textAlign="center">
            <br/>
            <Popup
                content={getDescriptiveHeb()}
                open
                position='bottom center'
                trigger={
                    <DateInput
                        localization="he"
                        inline
                        name="date"
                        placeholder="Date"
                        value={formattedDate()}
                        iconPosition="left"
                        onChange={handleChange}
                        marked={holidays}
                        markColor="yellow"
                    />
                }
            />
            <br/><br/><br/>
            <div>
                <img src={logo} className="logo-image"/>
                <div style={{marginBottom: "2vw"}}/>
            </div>
        </Container>
    );
}

export default Calendar;