import "./ShabbatTimes.css";
import React from 'react';
import {HebcalService} from "../../services/hebcal-service";

function ShabbatTimes() {
    const hebcalService = new HebcalService();
    const shabbatTimes = hebcalService.getShabbatTimes();
    const citiesTrans = {
        "Tel Aviv": "תל אביב",
        "Ashdod": "אשדוד",
        "Jerusalem": "ירושלים",
        "Beer Sheva": "באר שבע",
        "Haifa": "חיפה",
        "Eilat": "אילת",
    };
    const parasha = shabbatTimes[0]?.parasha?.render("he") ?? shabbatTimes[0]?.linkedEvent?.render("he") ?? shabbatTimes[0]?.candleLighting.getDate().greg().toLocaleString("he-IL").split(",")[0];
    const parashaName = parasha?.split(" ").filter((x, index)=> index!==0).join(" ");
    return (
        <div className="shabbat-times" dir="rtl">
            <div className="title">זמני כניסת שבת</div>
            <div className="parasha">
                {parasha?.split(" ")[0]} {parashaName ? `${parashaName}` : ""}
            </div>
            <div className="time">
                <div className="city"> </div>
                <div className="entrance" style={{textDecoration:"underline"}}>כניסה</div>
                <div className="exit" style={{textDecoration:"underline"}}>יציאה</div>
            </div>
            {shabbatTimes.map((x, index) => {
                const candleLightingTimeRep = x.candleLighting?.render().split(": ")[1];
                const havdalahTimeRep = x.havdalah?.render().split(": ")[1];
                return (<div className="time" key={index}>
                    <div className="city">{(citiesTrans as any)[x.city]}</div>
                    <div className="entrance">{candleLightingTimeRep}</div>
                    <div className="exit">{havdalahTimeRep}</div>
                </div>)
            })}
        </div>
    );
}

export default ShabbatTimes;