import React, {useState} from 'react';
import './App.css';
import Calendar from "./calendar/Calendar";
import {ShabbatContainer} from "./shabbat-container/ShabbatContainer";
import {Button, Icon} from "semantic-ui-react";


function App() {
    const [view, setView] = useState('shabbat');
    const changeView = (viewName: string) => {
        setView(viewName);
    };

    return (
        <div>
            {view === "shabbat" ? <ShabbatContainer/> :
                view === "calendar" ? <Calendar/> :
                    <ShabbatContainer/>}
            <div className="bottom-container">
                <a href="https://play.google.com/store/apps/details?id=com.ydev.shabbat" target="_blank">
                    <Button color='black' size="medium">
                        <Icon size="big" name='android'/> הורידו את האפליקציה לאנדרואיד
                    </Button>
                </a>
            </div>
            <div className="ui bottom fixed menu inverted bottomMenu" dir="rtl">
                <a onClick={() => changeView("shabbat")} className="item">זמני שבת</a>
                <a onClick={() => changeView("calendar")} className="item">לוח שנה</a>
                <a href="mailto: ydev.team.con@gmail.com" className="item">צור קשר</a>
                <span className="item">פותח ע"י YDev Applications</span>
            </div>
        </div>
    );
}

export default App;
