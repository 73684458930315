import {HebrewCalendar, HDate, Location, Event, CandleLightingEvent, HavdalahEvent, ParshaEvent} from '@hebcal/core';
import * as datefns from "date-fns";

export class HebcalService {

    getShabbatTimes() {
        const cities = ["Tel Aviv", "Ashdod", "Jerusalem", "Beer Sheva", "Haifa", "Eilat"];
        const {firstday, lastday} = this.getFirstAndLastDayOfTheWeek();
        let options = {
            start: firstday,
            end: lastday,
            isHebrewYear: false,
            candlelighting: true,
            sedrot: true,
            omer: false,
            noHolidays: true,
            noMinorFast: true,
            noRoshChodesh: true,
        };
        const res = [];
        for (const city of cities) {
            const opt = {...options, location: Location.lookup(city)};
            const events = HebrewCalendar.calendar(opt);
            console.log(events)
            const candleLighting = events.find(x => x instanceof CandleLightingEvent);
            const havdalah = events.find(x => x instanceof HavdalahEvent);
            const parasha = events.find(x => x instanceof ParshaEvent)
            const linkedEvent = (candleLighting as any).linkedEvent;


            res.push({city, candleLighting, havdalah, parasha, linkedEvent});
        }
        return res;
    }

    getEvent(date: Date){
        return HebrewCalendar.getHolidaysOnDate(date);
    }

    getAllHolidaysDates(){
        let options = {
            isHebrewYear: false,
            candlelighting: false,
            sedrot: false,
            omer: false,
            noHolidays: false,
            noMinorFast: false,
            noRoshChodesh: false,
        };
        return HebrewCalendar.calendar(options);
    }

    getHebrewDate(date?: Date) {
        return new HDate(date ?? new Date()).renderGematriya();
    }

    private getFirstAndLastDayOfTheWeek() {
        const firstday = new Date();
        const lastday = datefns.endOfWeek(new Date(), {weekStartsOn: 0});
        return {firstday, lastday};
    }


    private getClosestShabbat() {
        const func = function getNextDayOfWeek(date: Date, dayOfWeek: number) {
            // Code to check that date and dayOfWeek are valid left as an exercise ;)

            var resultDate = new Date(date.getTime());

            resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay()) % 7);

            return resultDate;
        };

        return func(new Date(), 7);
    }
}