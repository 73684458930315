import logo from "../assets/logo.png";
import {Button, Icon} from "semantic-ui-react";
import React from "react";
import ShabbatTimes from "./shabbat-times/ShabbatTimes";
import {HebcalService} from "../services/hebcal-service";

export function ShabbatContainer(){
    const hebcalService = new HebcalService();
    const hebdate = hebcalService.getHebrewDate();
    return (
        <div>
            <div className="app-container">
                <div className="shabbat-times-container">
                    <ShabbatTimes/>
                </div>
                <div className="logo-container">
                    <img src={logo} className="logo-image"/>
                    <div style={{marginBottom: "2vw"}}/>
                    <div className="todayDateTitle" style={{color: "white"}}>תאריך היום</div>
                    <div className="hebdate grad">
                        {hebdate}
                    </div>
                    <div style={{marginBottom: "2vw"}}/>
                    <div className="gregDate">
                        {new Date().toLocaleDateString("he-IL")}
                    </div>
                </div>
                {/*<div className="bottom-container">*/}
                {/*    <a href="https://play.google.com/store/apps/details?id=com.ydev.shabbat" target="_blank">*/}
                {/*        <Button color='black' size="medium">*/}
                {/*            <Icon size="big" name='android'/> הורידו את האפליקציה לאנדרואיד*/}
                {/*        </Button>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}